.captchaContainer {
  display: flex;
  align-items: center;
}

.retryButton {
  border: none;
  margin: 0 0 0 16px;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;
  text-align: inherit;
}
